.fullh {
  min-height: 100vh;
}

.fullw {
  min-width: 100vh;
}

.fitscale {
  height: 100vh;
}

.fitw {
  width: 100vh;
}




.neonGlow {
    -webkit-animation: neonGlow 1s ease-in-out infinite alternate;
    animation: neonGlow 1s ease-in-out infinite alternate;
    -webkit-filter: drop-shadow(0px 0px 10px cyan);
    filter: drop-shadow(0px 0px 10px cyan);
  }
  
  @-webkit-keyframes neonGlow {
    to {
      -webkit-filter: drop-shadow(0px 0px 10px cyan);
      filter: drop-shadow(0px 0px 10px cyan);
    }
  }
  
  @keyframes neonGlow {
    from {
      -webkit-filter: drop-shadow(0px 0px 10px #FF0E89);
      filter: drop-shadow(0px 0px 10px #FF0E89);
    }
    to {
      -webkit-filter: drop-shadow(0px 0px 10px cyan);
      filter: drop-shadow(0px 0px 10px cyan);
    }
  }

  .flipH {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

.neonGlow2 {
  -webkit-animation: neonGlow2 1.5s ease-in-out infinite alternate;
  animation: neonGlow2 1.5s ease-in-out infinite alternate;
  -webkit-filter: drop-shadow(3px 3px 7px rgba(0, 255, 255, 0.062));
  filter: drop-shadow(3px 3px 7px rgba(0, 255, 255, 0.062));
}

@keyframes neonGlow2 {
  from {
    -webkit-filter: drop-shadow(5px 3px 7px rgba(0, 255, 255, 0.062));
    filter: drop-shadow(3px 5px 6px rgba(0, 255, 255, 0.062));
  }
  to {
    -webkit-filter: drop-shadow(5px 5px 6px  rgba(255, 15, 139, 0.11));
    filter: drop-shadow(3px 5px 8px rgba(255, 15, 139, 0.11));
  }
}


.gradient-bg {
  background-image: linear-gradient(90deg, rgb(rgba(255, 15, 139, 0.11)), rgba(0, 255, 255, 0.062));
}


